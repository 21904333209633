import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const UnsubscribePage = () => {
  useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    let token = params.get("token")
    if (token !== null) {
      fetch(`${process.env.GATSBY_FIREBASE_URI}/unsubscribe`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      })
        .then(res => res.json())
        .then(data => console.log(data))
    }
  }, [])

  return (
    <Layout>
      <div className="m-auto max-w-6xl mt-16 text-center">
        <SEO title="Unsubscribe" />
        <h1 className="text-emphasis">Sad to see you go!</h1>
        <p>You've been unsubscribed from our emails! </p>
        <p>Let us know at team@matchmakerbyu.com if you want back in ;)</p>
      </div>
    </Layout>
  )
}

export default UnsubscribePage
